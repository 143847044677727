import { useFormContext } from 'react-hook-form';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';
import { buildInputClassName, InputProps } from './Input';
import InputError from './InputError';

type FormData = {
  email: string;
};

export default function EmailInput({
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
  placeholder,
}: InputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-email`;
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();
  const error = errors?.email;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        Email
      </label>
      <div className="mt-1">
        <input
          id={htmlId}
          type="email"
          autoComplete="email"
          maxLength={320}
          placeholder={placeholder ? 'Email' : undefined}
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          {...register('email', { required: true })}
        />
        <InputError error={error} label="Email" className={errorClassName} />
      </div>
    </div>
  );
}
