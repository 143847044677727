import { useFormContext } from 'react-hook-form';
import PrimaryButton, { PrimaryButtonProps } from '../Button/PrimaryButton';

type Props = Omit<PrimaryButtonProps, 'onClick' | 'type' | 'disabled'>;

export default function SubmitButton(props: Props) {
  const {
    formState: { isValidating, isSubmitting, isSubmitSuccessful },
  } = useFormContext();
  const disabled = isValidating || isSubmitting || isSubmitSuccessful;
  return <PrimaryButton type="submit" disabled={disabled} {...props} />;
}
