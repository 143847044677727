import { XIcon } from '@heroicons/react/solid';
import { useDrawer } from '.';
import PhoneLink from '../Button/PhoneLink';
import Disclaimer from '../FormSection/Disclaimer';
import CountryInput from '../Input/CountryInput';
import EmailInput from '../Input/EmailInput';
import FirstNameInput from '../Input/FirstNameInput';
import LastNameInput from '../Input/LastNameInput';
import PhoneInput from '../Input/PhoneInput';
import StreetInput from '../Input/StreetInput';
import SubmitButton from '../Input/SubmitButton';
import ZipInput from '../Input/ZipInput';
import LeadFormWrapper from '../LeadForm/LeadFormWrapper';
import LeadFormReviewBadge from '../ReviewBadge/LeadFormReviewBadge';

function LeadFormDrawerContent() {
  const { toggle } = useDrawer();
  return (
    <div>
      <div className="text-right">
        <button
          type="button"
          onClick={toggle}
          className="inline-flex rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600"
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
      <div className="mx-4">
        <h1 className="text-4xl font-bold text-gray-900">
          Get a free quote and EnergyPal offers.
        </h1>
        <LeadFormReviewBadge />
        <p className="mt-4 mb-4 text-xl leading-8 text-gray-700">
          Call{' '}
          <PhoneLink className="font-semibold underline text-primary hover:text-primary-700" />{' '}
          or complete the form and an EnergyPal advisor will contact you.
        </p>
        <LeadFormWrapper
          prefix="drawer"
          className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-3"
        >
          <FirstNameInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <LastNameInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <EmailInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <PhoneInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <StreetInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <CountryInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <ZipInput
            placeholder
            labelClassName="sr-only"
            inputClassName="bg-gray-100"
          />
          <div className="sm:col-span-2">
            <Disclaimer />
            <SubmitButton size="lg" />
          </div>
        </LeadFormWrapper>
      </div>
    </div>
  );
}

export default function LeadFormDrawer() {
  const { isOpen, toggle } = useDrawer();
  return (
    <>
      <aside
        className={`transform top-0 right-0 w-full sm:w-[32rem] bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-3">
          <LeadFormDrawerContent />
        </div>
      </aside>
      {isOpen && (
        <div
          onClick={toggle}
          className="fixed top-0 bottom-0 left-0 right-0 z-20 transition-opacity duration-300 ease-in-out bg-black opacity-50"
        />
      )}
    </>
  );
}
