// import FullWidthBarBreadcrumbs from '../Breadcrumb/FullWidthBarBreadcrumbs';
import React from 'react';
import FullWidthBarBreadcrumbs from '../Breadcrumb/FullWidthBarBreadcrumbs';
// import CenteredContentSection from '../ContentSection/CenteredContentSection';
// import MetaTags from '../Head/MetaTags';
// import SimpleCenteredHeaderSection from '../HeaderSection/SimpleCenteredHeaderSection';

type Props = {
  meta?: { [key: string]: string };
  children?: React.ReactNode;
};

export default function PostLayout({ meta, children }: Props) {
  return (
    <>
      {meta?.title && (
        <FullWidthBarBreadcrumbs
          pages={[
            {
              name: 'Resources',
              href: '/solar-customer-resources',
            },
            {
              name: 'Solar Overview',
              href: '/solar-overview',
            },
            {
              name: meta.title,
            },
          ]}
        />
      )}
      <div
        className="relative py-16 overflow-hidden bg-white"
        data-title={meta?.title || 'N/A'}
      >
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full mx-auto text-lg max-w-prose"
            aria-hidden="true"
          >
            <svg
              className="absolute transform translate-x-32 top-12 left-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute transform translate-x-32 bottom-12 left-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto prose prose-lg text-gray-500 prose-primary">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
