import { forwardRef, useEffect, useState } from 'react';

function format(value: string, country = 'US') {
  if (country === 'CA') {
    const chars = [];
    const input = value.toUpperCase().trim();
    if (input.length > 0 && input[0].match(/[ABCEGHJ-NPRSTVXY]/)) {
      chars.push(input[0]);
    }
    if (input.length > 1 && input[1].match(/\d/)) {
      chars.push(input[1]);
    }
    if (input.length > 2 && input[2].match(/[ABCEGHJ-NPRSTV-Z]/)) {
      chars.push(input[2]);
    }
    if (input.length > 3 && input[3].match(/[ -]/)) {
      chars.push(input[3]);
      if (input.length > 4 && input[4].match(/\d/)) {
        chars.push(input[4]);
      }
      if (input.length > 5 && input[5].match(/[ABCEGHJ-NPRSTV-Z]/)) {
        chars.push(input[5]);
      }
      if (input.length > 6 && input[6].match(/\d/)) {
        chars.push(input[6]);
      }
    } else {
      if (input.length > 3 && input[3].match(/\d/)) {
        chars.push(input[3]);
      }
      if (input.length > 4 && input[4].match(/[ABCEGHJ-NPRSTV-Z]/)) {
        chars.push(input[4]);
      }
      if (input.length > 5 && input[5].match(/\d/)) {
        chars.push(input[5]);
      }
    }
    return chars.join('');
  }
  return value.replace(/\D/g, '').substring(0, 5);
}

type InputProps = {
  onChange: (value: string) => void;
  value: string;
  country: string;
  className?: string;
  placeholder?: string;
  name?: string;
  id?: string;
};

const FormattingZipInput = forwardRef<HTMLInputElement, InputProps>(
  ({ country, value, onChange, className, id, name, placeholder }, ref) => {
    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
      if (!isTouched && value && value.length > 0) {
        const v = format(value, country);
        if (v !== value) {
          onChange(v);
        }
      }
    }, [isTouched, onChange, value, country]);
    const inputMode = country === 'CA' ? 'text' : 'numeric';
    return (
      <input
        id={id}
        name={name}
        type="text"
        inputMode={inputMode}
        autoComplete="postal-code"
        maxLength={7}
        className={className}
        placeholder={placeholder}
        ref={ref}
        value={value || ''}
        onChange={(evt) => {
          setIsTouched(true);
          const f = format(evt.target.value, country);
          onChange(f);
        }}
      />
    );
  }
);
FormattingZipInput.displayName = 'FormattingZipInput';

export default FormattingZipInput;
