import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import BlueOnWhiteLogo from '../Logo/BlueOnWhiteLogo';
import HeaderButton from './HeaderButton';
import Link from 'next/link';
import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';
import ResourcesFlyoutMenu from '../FlyoutMenu/ResourcesFlyoutMenu';
import ResourcesFlyoutMenuContent from '../FlyoutMenu/ResourcesFlyoutMenuContent';
import PrimaryButton from '../Button/PrimaryButton';
import { useDrawer } from '../Drawer';
import { XIcon } from '@heroicons/react/solid';

const menuLinks = [
  {
    name: 'Packages',
    href: '/compare',
  },
  {
    name: 'Specials',
    href: '/specials',
  },
  {
    name: 'Products & Services',
    href: '/products',
  },
  {
    name: 'Why Us',
    href: '/best-solar-company-reviews',
  },
  {
    name: 'Resources',
    href: '/solar-customer-resources',
  },
];

export default function PrimaryHeader() {
  const { toggle } = useDrawer();

  return (
    <Popover className="top-0 z-20 bg-white lg:sticky z-30 shadow" as="header">
      <div className="relative z-20">
        <div className="flex items-center justify-between px-4 py-5 mx-auto max-w-7xl sm:px-6 sm:py-4 lg:px-8 lg:justify-start lg:space-x-10">
          <div>
            <Link href="/" className="flex">
              <span className="sr-only">EnergyPal</span>
              <BlueOnWhiteLogo className="w-auto h-10 sm:h-10" />
            </Link>
          </div>
          <div className="lg:hidden">
            <PrimaryButton onClick={toggle} size="md">
              Free Quote
            </PrimaryButton>
          </div>
          <div className="-my-2 -mr-2 lg:hidden">
            <Popover.Button
              aria-label="Navigation Menu"
              className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 relative"
            >
              <div className="absolute h-full w-full" />
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
            <Popover.Group as="nav" className="flex space-x-5">
              {menuLinks.map(({ name, href }) => (
                <HeaderButton key={name} href={href}>
                  {name}
                </HeaderButton>
              ))}
              <ResourcesFlyoutMenu />
            </Popover.Group>
            <div className="flex items-center lg:ml-12">
              <a
                href={`tel:${PHONE_NUMBER}`}
                className="text-base font-medium text-primary hover:text-gray-900"
              >
                Call {PHONE_DISPLAY}
              </a>
              <PrimaryButton onClick={toggle} className="ml-8 bg-primary">
                Get a Free Quote
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform lg:hidden"
        >
          {({ close }) => (
            <>
              <Popover.Button
                as="button"
                aria-label="Close Menu"
                className="absolute w-6 h-6 text-gray-500 hover:text-gray-900 top-3 right-3"
              >
                <XIcon />
              </Popover.Button>
              <div className="bg-white divide-y-2 divide-gray-300 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-5 pt-6 pb-2">
                  <div className="grid grid-cols-1 gap-4">
                    {menuLinks.map(({ name, href }) => (
                      <Popover.Button as={Link} key={name} href={href}>
                        <Link
                          href={href}
                          className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700"
                          onClick={() => close()}
                        >
                          {name}
                        </Link>
                      </Popover.Button>
                    ))}
                    <a
                      href={`tel:${PHONE_NUMBER}`}
                      className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700"
                    >
                      {PHONE_DISPLAY}
                    </a>
                  </div>
                </div>
                <div className="px-3 border-t">
                  <ResourcesFlyoutMenuContent embedded />
                </div>
              </div>{' '}
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
