import { useFormContext } from 'react-hook-form';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';
import { buildInputClassName, InputProps } from './Input';
import InputError from './InputError';

type FormData = {
  address: {
    street: string;
  };
};

export default function StreetInput({
  containerClassName = 'sm:col-span-2',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
  placeholder,
}: InputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-street`;
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();
  const error = errors?.address?.street;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        Street Address
      </label>
      <div className="mt-1">
        <input
          id={htmlId}
          type="text"
          autoComplete="street-address"
          maxLength={500}
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          placeholder={placeholder ? 'Street Address' : undefined}
          {...register('address.street', { required: true })}
        />
        <InputError error={error} label="Street" className={errorClassName} />
      </div>
    </div>
  );
}
