import Cookies from 'js-cookie';
import regionAbbrToName from './region';

function getString(key: string): string {
  return Cookies.get(key) || '';
}
export function getLid() {
  return getString('lid');
}
function getGeo() {
  const raw = Cookies.get('geo') || null;
  if (raw) {
    return JSON.parse(raw);
  }
  return null;
}
export function getCountryCode() {
  const geo = getGeo();
  return geo?.country;
}
export function getRegionCode() {
  const geo = getGeo();
  return geo?.region;
}
export function getRegionName() {
  const region = getRegionCode();
  if (region) {
    return regionAbbrToName(region);
  }
  return null;
}
