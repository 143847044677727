import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

type DrawerContextProps = {
  isOpen: boolean;
  toggle?: () => void;
};

const DrawerContext = createContext<DrawerContextProps>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggle: () => {},
});
DrawerContext.displayName = 'DrawerContext';

export function useDrawer() {
  return useContext(DrawerContext); // { isOpen, toggle }
}

type Props = {
  children: React.ReactNode;
};
export function DrawerProvider({ children }: Props) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);
  useEffect(() => {
    const handleRouteChange = () => setIsOpen(false);
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggle = () => setIsOpen((v) => !v);

  const value = { isOpen, toggle };
  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
}
