import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ResourcesFlyoutMenuContent from './ResourcesFlyoutMenuContent';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function ResourcesFlyoutMenu() {
  return (
    <Popover className="flex">
      {({ open, close }) => (
        <>
          <Popover.Button
            aria-label="Resources Menu"
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
            )}
          >
            <ChevronDownIcon
              className={classNames(
                open ? 'text-gray-600' : 'text-gray-400',
                'h-5 w-5 group-hover:text-gray-500'
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            {/* absolute inset-x-0 z-10 transform shadow-lg */}
            <Popover.Panel className="absolute inset-x-0 z-10 hidden transform shadow-lg md:block top-full">
              <div className="absolute inset-0 flex" aria-hidden="true">
                <div className="w-1/2 bg-white" />
                <div className="w-1/2 bg-gray-50" />
              </div>
              <ResourcesFlyoutMenuContent close={close} />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
