import SmartLink from '../Button/SmartLink';

export default function SlimFooter() {
  return (
    <footer className="bg-gray-100">
      <div className="py-4 border-t border-gray-200">
        <p className="text-sm text-center text-gray-500">
          &copy; {new Date().getFullYear()} EnergyPal. All rights reserved.{' '}
          <SmartLink href="/privacy">Privacy Policy</SmartLink>.{' '}
          <SmartLink href="/terms">Terms of Service</SmartLink>.{' '}
          <SmartLink href="/fulfillment">Fulfillment Policy</SmartLink>.
        </p>
      </div>
    </footer>
  );
}
