import { useController, useFormContext } from 'react-hook-form';
import { buildInputClassName, InputProps, PHONE_REGEX } from './Input';
import InputError from './InputError';
import FormattingPhoneInput from './FormattingPhoneInput';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';

type FormData = {
  phone: number;
};

export default function PhoneInput({
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
  placeholder,
}: InputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-phone`;
  const {
    formState: { errors },
  } = useFormContext<FormData>();
  const {
    field: { value, onChange },
  } = useController({
    name: 'phone',
    rules: { required: true, pattern: PHONE_REGEX },
  });
  const error = errors?.phone;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        Phone Number
      </label>
      <div className="mt-1">
        <FormattingPhoneInput
          id={htmlId}
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          name="phone"
          value={value}
          placeholder={placeholder ? 'Phone' : undefined}
          onChange={onChange}
        />
        <InputError error={error} label="Phone" className={errorClassName} />
      </div>
    </div>
  );
}
