import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';
import FormattingZipInput from './FormattingZipInput';
import {
  buildInputClassName,
  InputProps,
  POSTAL_CODE_CA_REGEX,
  POSTAL_CODE_US_REGEX,
} from './Input';
import InputError from './InputError';
import { useRouter } from 'next/router';

type ZipInputProps = InputProps & {
  country: string;
};

export default function ZipInput(props: InputProps) {
  const { query } = useRouter();
  const queryZip = Array.isArray(query.zip)
    ? query.zip[0]?.toUpperCase()
    : query.zip?.toUpperCase();

  const country = useWatch({ name: 'address.country' }) || queryZip || 'US';
  return <ZipInputInner country={country} {...props} />;
}

const RULES_US = {
  required: true,
  pattern: POSTAL_CODE_US_REGEX,
};
const RULES_CA = {
  required: true,
  pattern: POSTAL_CODE_CA_REGEX,
};

type FormData = {
  address: {
    zip: string;
  };
};
function ZipInputInner({
  country,
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
  placeholder,
}: ZipInputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-zip`;
  const rules = country === 'US' ? RULES_US : RULES_CA;
  const {
    formState: { errors },
  } = useFormContext<FormData>();
  const {
    field: { value, onChange },
  } = useController({ name: 'address.zip', rules });
  const error = errors?.address?.zip;
  const label = country === 'US' ? 'Zip' : 'Postal Code';
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        {label}
      </label>
      <div className="mt-1">
        <FormattingZipInput
          id={htmlId}
          name="zip"
          country={country}
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          value={value}
          placeholder={placeholder ? label : undefined}
          onChange={onChange}
        />
        <InputError error={error} label={label} className={errorClassName} />
      </div>
    </div>
  );
}
