type Props = {
  children?: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function H3({ children, className, ...props }: Props) {
  return (
    <h3 className={['text-4xl font-bold', className].join(' ')} {...props}>
      {children}
    </h3>
  );
}
export function H4({ children, className, ...props }: Props) {
  return (
    <h4 className={['text-2xl', className].join(' ')} {...props}>
      {children}
    </h4>
  );
}
export function H5({ children, className, ...props }: Props) {
  return (
    <h5 className={['text-xl', className].join(' ')} {...props}>
      {children}
    </h5>
  );
}

export function L1({ children, className, ...props }: Props) {
  return (
    <li
      className={['text-black font-medium text-2xl', className].join(' ')}
      {...props}
    >
      {children}
    </li>
  );
}
