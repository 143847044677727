export const BUTTON_SIZES = {
  xs: 'px-3 py-1.5 text-xs',
  sm: 'px-3.5 py-2 text-sm leading-4',
  md: 'px-4 py-2 text-sm',
  lg: 'px-5 py-2 text-base',
  xl: 'px-6 py-3 text-xl',
};

export type ButtonSize = keyof typeof BUTTON_SIZES;

export type ButtonBaseType = {
  children?: React.ReactNode;
  className?: string;
  size?: ButtonSize; //'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export function buttonClasses({ size = 'lg', className }: ButtonBaseType) {
  const actualClassName = className || '';
  const defaultBg = actualClassName.includes('bg-') ? '' : 'bg-gray-900';
  return `${actualClassName} inline-flex items-center ${BUTTON_SIZES[size]} font-medium text-white uppercase ${defaultBg} disabled:opacity-75 border border-transparent rounded-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 justify-center`;
}

export function buttonOutlineClasses({
  size = 'lg',
  className,
}: ButtonBaseType) {
  return `${className} inline-flex items-center ${BUTTON_SIZES[size]} font-medium text-primary uppercase bg-transparent disabled:opacity-75 border border-primary hover:bg-primary rounded-full shadow-sm hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center `;
}
