import React from 'react';
import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';

type Props = {
  className?: string;
  phone?: string;
  phoneDisplay?: string;
};

export default function PhoneLink({
  className = 'text-base font-medium text-primary hover:text-gray-900',
  phone,
  phoneDisplay,
}: Props) {
  return (
    <a href={`tel:${phone || PHONE_NUMBER}`} className={className}>
      {phone ? phoneDisplay : PHONE_DISPLAY}
    </a>
  );
}
