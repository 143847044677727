import Image from 'next/legacy/image';
import React from 'react';
import GoogleLogo from 'images/featured-on/GLogo.png';

const LeadFormReviewBadge = () => {
  return (
    <div className="flex flex-row mt-2 mb-[-12px]">
      <div className="w-10 h-10 ml-[-6px]">
        <Image src={GoogleLogo} alt="Google logo" className="" />
      </div>
      <div className="flex items-center snap-center ">
        <span className="text-lg font-bold">4.9</span>
        <div className="ml-2">
          <span className="text-yellow-500">★</span>
          <span className="text-yellow-500">★</span>
          <span className="text-yellow-500">★</span>
          <span className="text-yellow-500">★</span>
          <span className="text-yellow-500">★</span>
        </div>
        <span className="text-md font-medium ml-1">(1404+)</span>
      </div>
    </div>
  );
};

export default LeadFormReviewBadge;
