import { forwardRef, useEffect, useState } from 'react';

function format(value: string) {
  const raw = (value || '').replace(/\D/g, '').substring(0, 10);
  const len = raw.length;
  if (len === 0) {
    return raw;
  } else if (len < 4) {
    return `(${raw}`;
  } else if (len < 7) {
    return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}`;
  } else {
    return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}-${raw.substring(
      6,
      10
    )}`;
  }
}
function toValue(formatted: string) {
  const raw = formatted.replace(/\D/g, '').substring(0, 10);
  return `+1${raw}`;
}

type InputProps = {
  onChange: (value: string) => void;
  value: string;
  className?: string;
  placeholder?: string;
  name?: string;
  id?: string;
};

const FormattingPhoneInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, className, id, name, placeholder }, ref) => {
    const [isTouched, setIsTouched] = useState(false);
    const [formatted, setFormatted] = useState(format(value));
    useEffect(() => {
      if (!isTouched && value && value.length > 0) {
        setFormatted(() => format(value));
      }
    }, [isTouched, value]);
    return (
      <input
        id={id}
        name={name}
        placeholder={placeholder}
        type="tel"
        inputMode="tel"
        autoComplete="tel-national"
        maxLength={14}
        className={className}
        ref={ref}
        value={formatted}
        onChange={(evt) => {
          setIsTouched(true);
          const f = format(evt.target.value);
          setFormatted(f);
          onChange(toValue(f));
        }}
      />
    );
  }
);
FormattingPhoneInput.displayName = 'FormattingPhoneInput';

export default FormattingPhoneInput;
