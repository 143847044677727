import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export default function PostH1({ children }: Props) {
  return (
    <h1>
      <span className="block mt-2 text-3xl font-bold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
        {children}
      </span>
    </h1>
  );
}
