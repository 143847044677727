import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';

const COOKIE_KEY = 'banner-bottom-cookie';
const COOKIE_VAL = 'closed';

export default function BottomFixedBanner() {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (Cookies.get(COOKIE_KEY) === COOKIE_VAL) {
      setShow(false);
    }
  }, []);
  const onClose = () => {
    setShow(false);
    Cookies.set(COOKIE_KEY, COOKIE_VAL, { expires: 365 });
  };
  return (
    <>
      {/*
      Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
      your content from being obscured when the user scrolls to the bottom of the page.
    */}
      <div className="fixed inset-x-0 bottom-0" hidden={!show}>
        <div className="bg-gray-600 bg-opacity-95">
          <div className="px-3 py-1 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center flex-1 w-0">
                <p className="ml-3 text-white truncate font-base">
                  We use cookies on our website. To learn more about cookies and
                  how we use them view our{' '}
                  <Link href="/privacy" className="underline">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
              <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="flex p-2 -mr-1 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  onClick={onClose}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
