import Script from 'next/script';

/***
 * TO WORK FOR INNER PAGE LOADS: [USE HISTORY CHANGE TRIGGER]
 * 1. Log into google tag manager.
 * 2. Click Triggers
 * 3. Click New -> Trigger Configuration and select History Change trigger.
 * 4. Make sure to click All History Changes.
 * 5. Name the trigger and click save.
 * 6. Don't forget to publish your workspace container.
 */

/**
 * Paste this code as high in the <head> of the page as possible:
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PRW3P75');</script>
<!-- End Google Tag Manager -->
 *
 * Additionally, paste this code immediately after the opening <body> tag:
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PRW3P75"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
 */

// const script = `
// window.dataLayer = window.dataLayer || [];
// window.dataLayer.push({
//   'gtm.start': new Date().getTime(),
//   event: 'gtm.js'
// });
// `;

// @see https://nextjs.org/docs/messages/next-script-for-ga
export default function GoogleTagManger() {
  const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
  if (!GTM_ID) {
    return <></>;
  }
  return (
    <>
      <script id="gtm-id" data-value={GTM_ID} />
      {/* <script id="gtm" dangerouslySetInnerHTML={{ __html: script }} /> */}
      <Script id="gtm" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
        strategy="afterInteractive"
      />
    </>
  );
}
