import { useState, useEffect } from 'react';
import Image from 'next/legacy/image';
import SolarHome from '@/images/popup/house.png';
import PrimaryButton from '../Button/PrimaryButton';
import Link from 'next/link';

const ExitIntentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);

  useEffect(() => {
    // Check if the URL contains "?popup=false"
    const queryParams = new URLSearchParams(window.location.search);
    const popupParam = queryParams.get('popup');

    if (popupParam === 'false') {
      setHasShownPopup(true);
      return;
    }
    let timeoutId: NodeJS.Timeout;

    const handleMouseLeave = (event: MouseEvent) => {
      if (!hasShownPopup && event.clientY <= 0) {
        // Set a timeout of 1 second before showing the popup
        timeoutId = setTimeout(() => {
          setShowPopup(true);
          setHasShownPopup(true);
        }, 700);
      }
    };

    const handleMouseEnter = () => {
      // Clear the timeout if the user returns to the page before 1 second
      clearTimeout(timeoutId);
    };

    document.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('mouseenter', handleMouseEnter);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('mouseenter', handleMouseEnter);
    };
  }, [hasShownPopup]);

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-4 max-w-[90%] mx-auto pb-5 w-[600px] h-[450px]">
            <div className="relative" onClick={() => setShowPopup(false)}>
              <h1 className="absolute top-0 right-0 hover:cursor-pointer text-lg font-bold text-gray-600">
                X
              </h1>
            </div>
            <div className="w-80 mx-auto mt-[-40px]">
              <Image src={SolarHome} alt="home with solar" />
            </div>
            <div className="flex flex-col items-center justify-center space-y-4 mt-8">
              <h1 className="text-3xl font-light">Wait!</h1>
              <h1 className="text-4xl font-semibold capitalize text-center px-2 md:px-24">
                Get a custom <br /> home solar quote.
              </h1>
              <Link href="/quote">
                <PrimaryButton>Get A free quote</PrimaryButton>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExitIntentPopup;
