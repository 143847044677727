import React from 'react'

type Props = {
  className?: string
}

export default function BlueOnWhiteLogo(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
      viewBox="0 0 493.661 121.696"
      {...props}
    >
      <clipPath id="p.0">
        <path d="M0 0h493.661v121.696H0V0z"></path>
      </clipPath>
      <g clipPath="url(#p.0)">
        <path
          fill="#000"
          fillOpacity="0"
          fillRule="evenodd"
          d="M0 0h493.661v121.696H0z"
        ></path>
        <path
          fill="#ff0"
          fillRule="evenodd"
          d="M83.873 27.448v23.087L60.786 27.448z"
        ></path>
        <path
          fill="#000"
          fillOpacity="0"
          fillRule="evenodd"
          d="M-13.043-48.283h519.748V117.92H-13.043z"
        ></path>
        <path
          fill="#15c"
          d="M79.713 77.487V89.72H34.745v-56h43.922v12.235H50.432v9.453h24.875v11.828H50.432v10.25h29.281zm36.323-31.844q8.078 0 13 4.797 4.922 4.797 4.922 14.484v24.797h-15.203V67.41q0-8.89-7.125-8.89-3.922 0-6.282 2.562-2.359 2.562-2.359 7.687v20.953H87.786v-43.36h14.484v4.72q2.563-2.641 6.078-4.032 3.516-1.406 7.688-1.406zm72.398 22.485q0 .156-.25 3.75h-31.516q.969 3.359 3.719 5.171 2.766 1.797 6.922 1.797 3.125 0 5.406-.875 2.281-.89 4.594-2.89l8 8.328q-6.313 7.031-18.484 7.031-7.594 0-13.36-2.875-5.75-2.89-8.922-8-3.156-5.125-3.156-11.61 0-6.39 3.078-11.468t8.516-7.953q5.453-2.89 12.25-2.89 6.484 0 11.765 2.687 5.282 2.672 8.36 7.75 3.078 5.078 3.078 12.047zM165.309 56.44q-3.516 0-5.875 2-2.36 2-3 5.438h17.75q-.64-3.438-3-5.438-2.36-2-5.875-2zm44.641-4.875q2.328-2.969 6.047-4.437 3.719-1.485 8.516-1.485v13.672q-2.079-.234-3.438-.234-4.89 0-7.656 2.64-2.75 2.64-2.75 8.078v19.922h-15.203v-43.36h14.484v5.204zm67.036-5.203v35.437q0 12.079-6.562 18.11-6.547 6.047-18.875 6.047-6.39 0-12-1.438-5.594-1.437-9.516-4.234l5.516-10.64q2.64 2.155 6.672 3.39 4.047 1.25 7.968 1.25 6 0 8.797-2.64 2.797-2.641 2.797-7.845v-1.437q-4.39 5.125-12.875 5.125-5.75 0-10.64-2.594-4.875-2.61-7.766-7.36-2.875-4.765-2.875-11.015 0-6.156 2.875-10.906 2.89-4.766 7.766-7.36 4.89-2.609 10.64-2.609 9.281 0 13.61 6v-5.281h14.468zm-24.468 29.047q4.078 0 6.75-2.485 2.687-2.484 2.687-6.406 0-3.922-2.64-6.36-2.641-2.437-6.797-2.437-4.172 0-6.86 2.438-2.672 2.437-2.672 6.36 0 3.921 2.72 6.405 2.718 2.485 6.812 2.485zm79.915-29.047l-18.719 45.125q-3.28 8-8 11.234-4.718 3.235-11.53 3.235-3.438 0-6.923-1.078-3.468-1.079-5.625-2.844l5.282-10.625q1.265 1.187 3.062 1.86 1.813.687 3.656.687 2.157 0 3.547-.875 1.406-.875 2.375-2.797l-18.562-43.922h15.593l10.641 26.484 10.719-26.484h14.484z"
        ></path>
        <path
          fill="#888"
          d="M362.674 33.721q7.593 0 13.187 2.532 5.61 2.515 8.64 7.203 3.048 4.671 3.048 10.984 0 6.328-3.047 10.969-3.031 4.625-8.64 7.156-5.595 2.516-13.188 2.516h-9.766v14.64h-15.844v-56h25.61zm-.969 28.875q4.89 0 7.36-2.11 2.484-2.124 2.484-6.046t-2.485-6.078q-2.468-2.156-7.359-2.156h-8.797v16.39h8.797zm50.885-16.953q10.797 0 16.469 4.922 5.687 4.922 5.687 15.078v24.078H420.59v-5.593q-3.281 6.312-12.797 6.312-5.047 0-8.734-1.75-3.672-1.766-5.547-4.766-1.875-3-1.875-6.843 0-6.157 4.719-9.547 4.718-3.406 14.562-3.406h8.625q-.39-6.891-9.187-6.891-3.125 0-6.329 1-3.203 1-5.437 2.766l-5.125-10.329q3.61-2.39 8.766-3.703 5.156-1.328 10.359-1.328zm-.563 35.203q2.641 0 4.641-1.281t2.875-3.766v-3.765h-6.547q-6.64 0-6.64 4.406 0 2 1.515 3.203 1.516 1.203 4.156 1.203zm32.319-50.484h15.203v59.36h-15.203v-59.36z"
        ></path>
      </g>
    </svg>
  )
}
