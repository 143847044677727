import { ButtonBaseType, buttonClasses } from './Button';

export type PrimaryButtonProps = ButtonBaseType & {
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function PrimaryButton({
  type = 'button',
  children = 'Get a Free Quote',
  className = '',
  size = 'lg',
  disabled,
  onClick,
}: PrimaryButtonProps) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={buttonClasses({ size, className })}
    >
      {children}
    </button>
  );
}
