import { createContext, useContext } from 'react';

type FormPrefixContextType = {
  prefix: string;
};
const DEFAULT_CONTEXT = { prefix: '' };

const FormPrefixContext = createContext<FormPrefixContextType>(DEFAULT_CONTEXT);

type FormPrefixProviderProps = {
  prefix: string;
  children: React.ReactNode;
};

export function useFormPrefix() {
  const { prefix } = useContext(FormPrefixContext);
  return prefix;
}

export function FormPrefixProvider({
  prefix,
  children,
}: FormPrefixProviderProps) {
  return (
    <FormPrefixContext.Provider value={{ prefix }}>
      {children}
    </FormPrefixContext.Provider>
  );
}
