import Image from 'next/legacy/image';
import PostImage1 from 'images/nem/What is Net Metering 3.0 .png';
import PostImage2 from 'images/solar-guide/Home Solar Guide.png';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdNotifications } from 'react-icons/md';
import { FaArrowRight } from 'react-icons/fa';
import Link from 'next/link';

const YEAR = new Date().getFullYear();

const company = [
  { name: 'Why EnergyPal', href: '/best-solar-company-reviews' },
  { name: 'About EnergyPal', href: '/our-story' },
  { name: 'EnergyPal Solar Reviews', href: '/reviews' },
  { name: 'Referral Program', href: '/refer' },
  {
    name: 'EnergyPal Brand Assets',
    href: '/company-brand-assets',
  },
  { name: 'Contact Us', href: '/contact-us-customer-care' },
];
const resources = [
  { name: 'Best Solar Panels', href: '/best-solar-panels-for-homes' },
  {
    name: 'Best Home Batteries',
    href: '/best-home-battery-systems',
  },
  { name: 'Best Solar Inverters', href: '/best-solar-inverters-for-homes' },
  {
    name: 'Solar Rebates & Incentives',
    href: '/home-battery-solar-installers-near-me',
  },
  { name: 'Home Solar FAQs', href: '/how-solar-works' },

  { name: 'Financing Solar', href: '/solar-financing-lease-loan' },
];
const blogPosts = [
  {
    id: 1,
    name: "NEM 3.0 And It's Impact In California",
    href: '/solar-rebates-incentives-and-net-metering-programs/what-is-nem-3-and-how-it-affects-california',
    preview: 'Click to learn more about net metering policies in California.',
    imageUrl: PostImage1,
  },
  {
    id: 2,
    name: 'Home Solar Guide',
    href: '/home-solar-guide',
    preview: `Explore the value of solar panels for your home with our comprehensive ${YEAR} guide.`,
    imageUrl: PostImage2,
  },
];

type Props = {
  embedded?: boolean;
  close?: () => void;
};

export default function ResourcesFlyoutMenuContent({
  embedded = false,
}: Props) {
  const pt = embedded ? 'pt-3' : 'pt-8';
  return (
    <div className="relative grid grid-cols-1 mx-auto max-w-7xl lg:grid-cols-2">
      <nav
        className={`grid px-4 pb-8 ${pt} bg-white gap-y-10 sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12`}
        aria-labelledby="resources-heading"
      >
        <h2 id="resources-heading" className="sr-only">
          Resources menu
        </h2>
        <div>
          <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
            Company
          </h3>
          <ul role="list" className="mt-5 space-y-6">
            {company.map((item) => (
              <li key={item.name} className="flow-root">
                <a
                  href={item.href}
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                >
                  <span className="ml-4">{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
            Resources
          </h3>
          <ul role="list" className="mt-5 space-y-6">
            {resources.map((item) => (
              <li key={item.name} className="flow-root">
                <a
                  href={item.href}
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                >
                  <span className="ml-4">{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <div className="px-4 py-8 bg-gray-50 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
        <div>
          <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
            HIGHLIGHTS
          </h3>
          <Link href="/shop">
            <div className="bg-gradient-to-r from-primary-500 to-cyan-500 py-2 rounded-lg mt-2 flex flex-row justify-between items-center px-5">
              <div className="flex flex-row items-center space-x-3">
                <MdNotifications className="text-2xl text-white" />
                <p className="capitalize font-medium text-white text-base md:text-lg">
                  EcoFlow Batteries now sold on EnergyPal
                </p>
              </div>
              <FaArrowRight className="text-xl text-white" />
            </div>
          </Link>
          <ul role="list" className="mt-6 space-y-6">
            {blogPosts.map((post) => (
              <li key={post.id} className="flow-root">
                <a
                  href={post.href}
                  className="flex p-3 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100"
                >
                  <div className="flex-shrink-0 hidden sm:block items-center">
                    <Image
                      className="object-cover w-32 h-20 rounded-md"
                      src={post.imageUrl}
                      alt={post.name}
                      width={128}
                      height={80}
                    />
                  </div>
                  <div className="flex-1 min-w-0 sm:ml-8">
                    <h4 className="text-base font-medium text-gray-900 truncate">
                      {post.name}
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">{post.preview}</p>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between">
          <div className="mt-6 text-sm font-medium">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              href="/news"
              className="transition duration-150 ease-in-out text-primary-600 hover:text-primary-500"
            >
              Latest Solar News <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
          <div className="mt-6 text-sm font-medium">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              href="/search"
              className="flex items-center transition duration-150 ease-in-out text-primary-600 hover:text-primary-500"
            >
              <span className="mr-1">Search</span>
              <span
                aria-hidden="true"
                className="inline-block w-4 h-4 text-primary-600 fill-current"
              >
                <AiOutlineSearch />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
