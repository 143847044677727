import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';
import SmartLink from '../Button/SmartLink';

type Props = {
  className?: string;
};
const navigation = {
  solutions: [
    { name: 'Home Solar Panels', href: '/best-solar-panels-for-homes' },
    { name: 'Home Battery Backup', href: '/best-home-battery-systems' },
    { name: 'Solar Inverters', href: '/best-solar-inverters-for-homes' },
    { name: 'Solar Financing', href: '/solar-financing-lease-loan' },
  ],
  support: [
    { name: 'Why EnergyPal', href: '/best-solar-company-reviews' },
    { name: 'About EnergyPal', href: '/our-story' },
    { name: 'EnergyPal Reviews', href: '/reviews' },
    { name: 'Careers', href: 'https://energypal.zohorecruit.com/careers' },
  ],
  company: [
    {
      name: 'Become a Partner',
      href: '/become-a-partner',
    },
    {
      name: 'Service Areas',
      href: '/home-battery-solar-installers-near-me',
    },
    {
      name: 'Brand Assets',
      href: '/company-brand-assets',
    },
    { name: 'Partner Login', href: 'https://partner.energypal.com/login' },
  ],
  legal: [
    { name: 'Support', href: '/contact-us-customer-care' },
    { name: 'Contact Us', href: '/contact' },
    { name: PHONE_DISPLAY, href: `tel:${PHONE_NUMBER}` },
    { name: 'Resources', href: '/solar-customer-resources' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/joinEnergyPal',
      icon: (props: Props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/energypalsolar/',
      icon: (props: Props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/energypalsolar',
      icon: (props: Props) => (
        <svg fill="currentColor" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'Houzz',
      href: 'https://www.houzz.com/professionals/solar-energy-contractors/energypal-solar-pfvwus-pf~1010879881',
      icon: (props: Props) => (
        <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          fill="currentColor"
          enableBackground="new 0 0 94 100"
          version="1.1"
          viewBox="0 0 94 100"
          xmlSpace="preserve"
        >
          <path d="M54 80L74 80 74 45.5 34 34 34 20 20 20 20 80 40 80 40 60 54 60z"></path>
          <path d="M0 0H94V100H0z" fill="none"></path>
        </svg>
      ),
    },
    // {
    //   name: 'Pinterest',
    //   href: 'https://www.pinterest.com.mx/energypal/',
    //   icon: (props: Props) => (
    //     <svg
    //       fill="currentColor"
    //       viewBox="0 0 256 256"
    //       preserveAspectRatio="xMidYMid"
    //       {...props}
    //     >
    //       <path d="M0 128.002c0 52.414 31.518 97.442 76.619 117.239-.36-8.938-.064-19.668 2.228-29.393 2.461-10.391 16.47-69.748 16.47-69.748s-4.089-8.173-4.089-20.252c0-18.969 10.994-33.136 24.686-33.136 11.643 0 17.268 8.745 17.268 19.217 0 11.704-7.465 29.211-11.304 45.426-3.207 13.578 6.808 24.653 20.203 24.653 24.252 0 40.586-31.149 40.586-68.055 0-28.054-18.895-49.052-53.262-49.052-38.828 0-63.017 28.956-63.017 61.3 0 11.152 3.288 19.016 8.438 25.106 2.368 2.797 2.697 3.922 1.84 7.134-.614 2.355-2.024 8.025-2.608 10.272-.852 3.242-3.479 4.401-6.409 3.204-17.884-7.301-26.213-26.886-26.213-48.902 0-36.361 30.666-79.961 91.482-79.961 48.87 0 81.035 35.364 81.035 73.325 0 50.213-27.916 87.726-69.066 87.726-13.819 0-26.818-7.47-31.271-15.955 0 0-7.431 29.492-9.005 35.187-2.714 9.869-8.026 19.733-12.883 27.421a127.897 127.897 0 0036.277 5.249c70.684 0 127.996-57.309 127.996-128.005C256.001 57.309 198.689 0 128.005 0 57.314 0 0 57.309 0 128.002z"></path>
    //     </svg>
    //   ),
    // },
    {
      name: 'Google',
      href: '/reviews',
      icon: (props: Props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            // fill="#4285F4"
            d="M-3.264 51.509c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82z"
            transform="translate(27.009 -39.239)"
          ></path>
          <path
            // fill="#34A853"
            d="M-14.754 63.239c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09c1.97 3.92 6.02 6.62 10.71 6.62z"
            transform="translate(27.009 -39.239)"
          ></path>
          <path
            // fill="#FBBC05"
            d="M-21.484 53.529c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29v-3.09h-3.98a11.86 11.86 0 000 10.76l3.98-3.09z"
            transform="translate(27.009 -39.239)"
          ></path>
          <path
            // fill="#EA4335"
            d="M-14.754 43.989c1.77 0 3.35.61 4.6 1.8l3.42-3.42c-2.07-1.94-4.78-3.13-8.02-3.13-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96z"
            transform="translate(27.009 -39.239)"
          ></path>
        </svg>
      ),
    },
  ],
};

export default function PrimaryFooter() {
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 mt-2 md:mt-12 xl:mt-0 xl:col-span-2">
            <div className="flex w-full col-span-2 mx-auto space-x-6 place-self-start md:hidden">
              {navigation.social.map((item) => (
                <SmartLink
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </SmartLink>
              ))}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">
                  Products
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <SmartLink
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                      >
                        {item.name}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">
                  About Us
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <SmartLink
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                      >
                        {item.name}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">
                  Partnerships
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <SmartLink
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                      >
                        {item.name}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">
                  Customer Service
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <SmartLink
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                      >
                        {item.name}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="hidden space-y-4 xl:col-span-1 md:block">
            <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase">
              Follow Us
            </h3>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <SmartLink
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </SmartLink>
              ))}
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base text-gray-500 xl:text-center">
            &copy; {new Date().getFullYear()} EnergyPal. All rights reserved.{' '}
            <SmartLink href="/privacy">Privacy Policy</SmartLink>.{' '}
            <SmartLink href="/terms">Terms of Service</SmartLink>.{' '}
            <SmartLink href="/fulfillment">Fulfillment Policy</SmartLink>. 8005
            Financial Dr Suite 323, Brampton, ON L6Y 6A1, Canada
          </p>
        </div>
      </div>
    </footer>
  );
}
