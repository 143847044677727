import { useFormContext } from 'react-hook-form';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';
import { buildInputClassName, InputProps } from './Input';
import InputError from './InputError';
import { useRouter } from 'next/router';

type FormData = {
  address: {
    country: string;
  };
};

export default function CountryInput({
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
}: InputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-country`;
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<FormData>();
  const { query } = useRouter();
  const queryCountry = Array.isArray(query.zip)
    ? query.zip[0]?.toUpperCase()
    : query.zip?.toUpperCase();

  const countryValue = watch('address.country') || queryCountry || 'US';
  const error = errors?.address?.country;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        Country
      </label>
      <div className="mt-1">
        <select
          id={htmlId}
          autoCapitalize="country"
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          {...register('address.country', { required: true })}
          defaultValue={countryValue}
        >
          <option value="US">United States</option>
          <option value="CA">Canada</option>
        </select>

        <InputError error={error} label="Country" className={errorClassName} />
      </div>
    </div>
  );
}
