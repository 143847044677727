import { FieldError } from 'react-hook-form';

type Props = {
  label?: string;
  className?: string;
  style?: 'rounded' | 'block';
  error?: FieldError;
};

function buildErrorMessage({ error, label }: Props) {
  if (error?.message) {
    return error.message;
  }
  if (error?.type === 'required') {
    return `${label} is required`;
  }
  // maxLength, minLength, max, min, pattern, validate
  return `${label} is invalid`;
}

export default function InputError({ error, className = '', label }: Props) {
  if (!error) {
    return <></>;
  }
  return (
    <p className={`mt-2 text-left text-sm text-red-600 ${className}`}>
      {buildErrorMessage({ error, label })}
    </p>
  );
}
