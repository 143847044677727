import Link from 'next/link';

type Props = {
  href: string;
  children: React.ReactNode;
};

export default function HeaderButton({ href, children }: Props) {
  return (
    <Link
      href={href}
      className="text-base font-medium text-gray-500 hover:text-gray-900"
    >
      {children}
    </Link>
  );
}
