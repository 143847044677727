import { getCountryCode } from 'lib/tracking';

const BASE_CLASSES =
  'block w-full px-4 rounded-full shadow-sm border-gray-300 focus:ring-primary-500 focus:border-primary-500';
const ERROR_CLASSES =
  'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500';

export const PHONE_REGEX = /^\+1\d{10}$/;
export const POSTAL_CODE_CA_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const POSTAL_CODE_US_REGEX = /^\d{5}$/;

export type InputProps = {
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  placeholder?: boolean;
};

type InputClassNameProps = {
  className?: string;
  hasError?: boolean;
};

export function buildInputClassName({
  hasError = false,
  className = '',
}: InputClassNameProps) {
  return hasError
    ? `${BASE_CLASSES} ${ERROR_CLASSES} ${className}`
    : `${BASE_CLASSES} ${className}`;
}

export type LeadFormData = {
  cid?: string;
  sid?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ownership?: string;
  electricBill?: number;
  address: {
    street?: string;
    country?: string;
    zip?: string;
  };
};

export function buildLeadFormDefaultValues(cid = '', sid = '') {
  return {
    cid: cid || '',
    sid: sid || '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ownership: '',
    // electricBill: null,
    address: {
      street: '',
      country: getCountryCode() || 'US',
      zip: '',
    },
  };
}
