import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { LeadFormData } from '../Input/Input';
import { FormPrefixProvider } from './FormPrefixContext';
import Cookies from 'js-cookie';

type Props = {
  prefix: string;
  cid?: string;
  sid?: string;
  children: React.ReactNode;
  className?: string;
};

export default function LeadFormWrapper({
  prefix,
  cid = '',
  sid = '',
  children,
  className = '',
}: Props) {
  const router = useRouter();
  const methods = useForm<LeadFormData>({
    defaultValues: { cid, sid },
  });

  const onSubmit = async (values: LeadFormData) => {
    const sidCookie = Cookies.get('sid');
    values.sid = sidCookie ? sidCookie : '';
    const response = await fetch('/api/leads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Extracting data from the response
    const responseData = await response.json();

    // Use the eventId from the response
    const eventId = responseData.eventId;

    Cookies.set('event_id', eventId); // Store the EVENT_ID in a cookie
    router.push('/thanks');
  };

  return (
    <FormPrefixProvider prefix={prefix}>
      <FormProvider {...methods}>
        <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
          {children}
          <input type="hidden" {...methods.register('cid')} />
          <input type="hidden" {...methods.register('sid')} />
        </form>
      </FormProvider>
    </FormPrefixProvider>
  );
}
