import Link from 'next/link';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

type Props = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'target' | 'rel'
> & {
  ref?: React.Ref<HTMLAnchorElement>;
};

export default function SmartLink({ href, ...props }: Props) {
  if (href?.startsWith('/') || href?.startsWith('#')) {
    return <Link href={href} {...props}></Link>;
  }
  return <a {...props} href={href} target="_blank" rel="noopener noreferrer" />;
}
