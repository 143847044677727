export default function Disclaimer() {
  return (
    <p className="mb-4 text-xs text-gray-700">
      Thanks for your interest in EnergyPal! By clicking below, you agree
      EnergyPal may call and text you about the products and services of up to
      four solar installers at the number provided even if on a &quot;do not
      call&quot; list, using either pre-recorded messages or automated means.
      Msg and data rates may apply. Your consent is not required to make a
      purchase.
    </p>
  );
}
