import { useFormContext } from 'react-hook-form';
import { useFormPrefix } from '../LeadForm/FormPrefixContext';
import { buildInputClassName, InputProps } from './Input';
import InputError from './InputError';

type FormData = {
  lastName: string;
};

export default function LastNameInput({
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  errorClassName = 'ml-4',
  placeholder,
}: InputProps) {
  const prefix = useFormPrefix();
  const htmlId = `${prefix}-lastName`;
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();
  const error = errors?.lastName;
  return (
    <div className={containerClassName}>
      <label
        htmlFor={htmlId}
        className={
          labelClassName || 'block pl-4 ml-px text-sm font-medium text-gray-700'
        }
      >
        Last Name
      </label>
      <div className="mt-1">
        <input
          type="text"
          id={htmlId}
          className={buildInputClassName({
            hasError: !!error,
            className: inputClassName,
          })}
          autoComplete="family-name"
          maxLength={100}
          placeholder={placeholder ? 'Last Name' : undefined}
          {...register('lastName', { required: true })}
        />
        <InputError
          error={error}
          label="Last Name"
          className={errorClassName}
        />
      </div>
    </div>
  );
}
