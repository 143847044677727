import SlimFooter from '../Footer/SlimFooter';

type Props = {
  children?: React.ReactNode;
};

export default function LandingLayout({ children }: Props) {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">{children}</main>
      <SlimFooter />
    </div>
  );
}
