import BottomFixedBanner from '../Banner/BottomFixedBanner';
// import LocationDealTopFixedBanner from '../Banner/LocationDealTopFixedBanner';
import PrimaryFooter from '../Footer/PrimaryFooter';
import PrimaryHeader from '../Header/PrimaryHeader';

type Props = {
  children: React.ReactNode;
};

export default function PrimaryLayout({ children }: Props) {
  return (
    <>
      {/* <LocationDealTopFixedBanner /> */}
      <PrimaryHeader />
      <main>{children}</main>
      <PrimaryFooter />
      <BottomFixedBanner />
    </>
  );
}
